<template>
  <div class="page">
    <div class="title">发放记录</div>

    <el-divider></el-divider>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <!-- picker -->
        <el-date-picker v-model="search.date" type="month" value-format="yyyy-MM"></el-date-picker>

        <!-- input -->
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select">搜索</div>
      </div>
    </div>

    <div class="log_list">
      <div class="log_item" v-for="item in 3" :key="item">
        <div class="log_item_head">
          <div>月工资表2</div>
          <div>管理员 创建于 2018-08-17 19:53</div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="log_item_info">
          <div>
            <div>总人数</div>
            <div>3人</div>
          </div>
          <div>
            <div>已发放</div>
            <div>3人</div>
          </div>
          <div>
            <div>已查看</div>
            <div>3人</div>
          </div>
          <div>
            <div>已确认</div>
            <div>3人</div>
          </div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="log_item_button">查看详情</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        keyword: "",
        date: this.$options.filters.formatTime(new Date(), "yyyy-MM"),
      },
      tableData: [],

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100% - 50px);
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .el-date-editor {
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }
  }

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.log_list {
  .log_item {
    background: #ffffff;
    border: 1px solid #f0f4f8;
    border-radius: 5px;
    margin: 20px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 25px 0;

    .el-divider {
      width: 1px;
      height: 36px;
    }

    .log_item_head {
      > div:nth-child(1) {
        font-size: 20px;
        font-weight: bold;
        color: #1c2438;
      }

      > div:nth-child(2) {
        font-size: 14px;
        color: #495060;
        margin-top: 15px;
      }
    }

    .log_item_info {
      width: 47%;
      display: flex;
      justify-content: space-between;

      > div {
        > div:nth-child(1) {
          font-size: 14px;
          color: #80848f;
        }

        > div:nth-child(2) {
          font-size: 14px;
          color: #1c2438;
        }
      }
    }

    .log_item_button {
      font-size: 12px;
      color: #406bff;
    }
  }
}
</style>
